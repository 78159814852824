.bg-white {background-color: white;}
.bg-brain-pain-low {background-color: #04AAB5;}
.bg-brain-pain-moderate {background-color: #F0AD4E;}
.bg-brain-pain-high {background-color: #D9534F;}
.bg-default, .hover-bg-default:hover {background-color: #0D2549;}
.bg-default-light, .hover-bg-default-light:hover {background-color: #0D254999;}
.bg-past {background-color: #3ae1cf;}
.bg-banana-yellow {background-color: #fbc015;}
.bg-present {background-color: #fbc015;}
.bg-future {background-color: #eb3f77;}
.bg-purple3 {background-color: #7933FE;}
.bg-purple4 {background-color: #7933FE24;}
.bg-purple5 {background-color: #EADFFF;}
.bg-purple6 {background-color: #EBECFF;}
.bg-button-see-customize {background-color: #7933FE;}
.bg-button-see-details {background-color: #7933FE;}
.bg-button-see-text {background-color: #EB4076;}
.bg-orange {background-color: #FFA500;}
.bg-dark-blue {background-color: #000080;}
.bg-purple {background-color: #800080;}
.bg-purple8 {background-color: #D7C4E4;}
.bg-gray {background-color: #F5F8FC;}
.bg-gray3 {background-color: #F5F8FC;}
.bg-gray4 {background-color: #7A7976;}
.bg-gray-12 {background-color: #E1E1E1;}
.bg-gray-light {background-color: #F7F9FC;}
.bg-gray-6 {background-color: #495057;}
.bg-red9 {background-color: #EB4076;}
.bg-transparent {background-color: transparent;}
.bg-target-green {background-color: #EBFEFF;}
.bg-target-yellow {background-color: #FFFAE9;}
.bg-target-positive {background-color: #E8F6F3;}
.bg-target-red {background-color: #FFECEF;}
.bg-target-negative {background-color: #FFECEF;}
.bg-before {background-color: #FFECEF;}
.bg-after {background-color: #EBFEFF;}
.bg-positive {background-color: black;}
.bg-negative {background-color: #FFECEF;}

.bg-neutral {background-color: #FFFAE9;}
.bg-high {background-color: #EADFFF;}
.bg-moderate {background-color: #EBFEFF;}
.bg-low {background-color: #E0FEE8;}
.bg-dark-red {background-color: #C63232;}
.bg-dark-green {background-color: #007C23;}
.bg-dark-yellow {background-color: #FBC016;}
.bg-yellow {background-color: #FDFCC7;}
.bg-target-positive {color: #2F9E7F;}
.bg-long {background-color: #FFF0C8;}
.bg-very-long {background-color: #CEFFB0;}
.bg-left-heavy {background-color: #CAFFDF;}
.bg-passive {background-color: #C6C8FF;}
.bg-non-finite {background-color: #F5EBFF;}
.bg-more-than-1 {background-color: #FFECEF;}
.bg-customize-alternative-words {background-color: #CAFFDF;}
.bg-positive-action {background-color: #00B832;}
.bg-tigim-blue {background-color: #31D3E1;}
.bg-a1 {background-color: #64EBB9;}
.bg-a2 {background-color: #95B935;}
.bg-b1 {background-color: #F9F871;}
.bg-b2 {background-color: #FFC244;}
.bg-c1 {background-color: #FF7F51;}
.bg-c2 {background-color: #C63232;}
.color-a1 {color: #20C997;}
.color-a2 {color: #4BBF73;}
.color-b1 {color: #F0AD4E;}
.color-b2 {color: #FD7E14;}
.color-c1 {color: #D9534F;}
.color-c2 {color: #8B0000;}
.color-long {color: #B87900;}
.color-very-long {color: #318100;}
.color-left-heavy {color: #005A24;}
.color-passive {color: #0007B6;}
.color-non-finite {color: #7507E3;}
.color-more-than-1 {color: #C63232;}
.color-positive {color: #007C23;}
.color-negative {color: #C63232;}
.color-neutral {color: #D89B00;}
.color-high {color: #7933FE;}
.color-moderate {color: #00BBEB;}
.color-low {color: #02B573;}
.color-positive-action {color: #00B832;}
.color-target-green {color: #04AAB5;}
.color-target-yellow {color: #C56B03;}
.color-target-red, .color-target-negative {color: #C63232;}
.color-target-positive {color: #2F9E7F;}
.color-before {color: #C63232;}
.color-after {color: #04AAB5;}
.color-default {color: #0D2549;}
.color-default-light {color: #0D254999;}
.color-dark-blue {color: #000080;}
.color-white {color: white;}
.color-black {color: black;}
.color-gray {color: gray;}
.color-gray-4 {color: #7A7976;}
.color-gray-6 {color: #495057;}
.color-gray-10 {color: #3F3F3F;}
.color-gray-dark {color: #263238;}
.color-past {color: #3ae1cf;}
.color-present {color: #fbc015;}
.color-future {color: #eb3f77;}
.color-purple3 {color: #7933FE;}
.color-purple8 {color: #D7C4E4;}
.color-purple9 {color: #4A144B;}
.color-red9 {color: #EB4076;}
.color-link {color: #0057FF;}
.color-yellow7 {color: #B28830;}
.color-gray-13 {color: #606060;}
.color-customize-alternative-words {color: #005A24;}
.b-top-color-blue_light2 {border-top-color: #C8FCFF;}
.bc-a1 {border-color: #64EBB9;}
.bc-a2 {border-color: #95B935;}
.bc-b1 {border-color: #F9F871;}
.bc-b2 {border-color: #FFC244;}
.bc-c1 {border-color: #FF7F51;}
.bc-c2 {border-color: #C63232;}
.bc-gray-14 {color: #11111333;}
.bc-purple3 {border-color: #7933FE;}
.bc-red9 {border-color: #EB4076;}
.bc-white {border-color: white;}
.bc-default {border-color: #0D2549;}
.bc-gray-6 {border-color: #495057;}
.bc-gray-12 {border-color: #D9D9D9;}
.bc-gray11 {border-color: #CFCFD0;}
.bc-gray-14 {border-color: #11111333;}
.bc-target-green {border-color: #04AAB5;}
.bc-dark-blue {border-color: #000080;}
.bc-transparent {border-color: transparent;}
.bc-gray-15 {border-color: rgba(17, 17, 19, 0.2)}
.bc-gradient-mask {position: relative;}
.bc-gradient-mask::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 10px;
  background:linear-gradient(45deg,red,blue); 
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
.box {
  position: relative;
}
.bc-bottom-purple3 {border-bottom-color: #7933FE;}
.bc-bottom-gray4 {border-bottom-color: #7A7976;}
.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px; 
  padding: 10px; 
  background:linear-gradient(45deg,red,blue); 
  -webkit-mask-composite: xor;
  -webkit-mask:linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask-composite: exclude; 
}
.bis {
  position: relative;
  background-color: transparent;
  width: 240px;
  height: 240px;
}
.bis::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 5px;
  -webkit-mask-composite: xor;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.bis-txt::before {
  background: linear-gradient(94.4deg, #FBC016 -4.65%, #EB4076 105.61%);
}
.bis-pdf::before {
  background: linear-gradient(94.4deg, #30D4DE -4.65%, #7933FE 105.61%);
}
.bis-ux::before {
  background: linear-gradient(94.4deg, #EB4076 -4.65%, #7933FE 105.61%);
}
.bis > div {
  height: 210px;
  width: 210px;
  margin-left: -2px;
  margin-top: -2px;
}
.bis-txt.active > div {
  background: linear-gradient(94.4deg, #FBC016 -4.65%, #EB4076 105.61%);
}
.bis-pdf.active > div {
  background: linear-gradient(94.4deg, #30D4DE -4.65%, #7933FE 105.61%);
}
.bis-ux.active > div {
  background: linear-gradient(94.4deg, #EB4076 -4.65%, #7933FE 105.61%);
}
.bg-purple3-to-blue9 {background: linear-gradient(93.02deg, #7933FE -12.7%, #30D4DE 121.16%);}
.bg-blue9-to-purple3, .hover-bg-blue9-to-purple3:hover {
  background: linear-gradient(93.02deg, #30D4DE -12.7%, #7933FE 121.16%);
}
.bg-red9-to-dark-yellow {background: linear-gradient(279.22deg,#EB4076 -34.08%,#FBC016 173.08%);}
.bg-default-to-purple3 {background: linear-gradient(129.33deg, #0D2549 1.73%, #7933FE 99.85%);}
.bg-red9-to-purple3 {background: linear-gradient(94.49deg, #EB4076 1.12%, #7933FE 116.5%);}
.fill-default, .hover-fill-default:hover {fill: #0D2549;}
.fill-purple3 {fill: #7933FE;}
.fill-default-light {fill: #0D254999;}
.fill-white, .hover-fill-white:hover {fill: white;}
.fill-blue9, .hover-fill-blue9 {fill: #30D4DE;}
.hover-fill-gradOthers:hover {fill: url(#gradOthers);}
.hover-color-purple3:hover {color: #7933FE;}
.hover-bc-purple3:hover {border-color: #7933FE;}
