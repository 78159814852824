.f-10 {font-size: 10px;}
.f-11 {font-size: 11px;}
.f-12 {font-size: 12px;}
.f-13 {font-size: 13px;}
.f-14 {font-size: 14px;}
.f-15 {font-size: 15px;}
.f-16 {font-size: 16px;}
.f-18 {font-size: 18px;}
.f-20 {font-size: 20px;}
.f-21 {font-size: 21px;}
.f-22 {font-size: 22px;}
.f-24 {font-size: 24px;}
.f-28 {font-size: 28px;}
.f-32 {font-size: 32px;}
.fw-100 {font-weight: 100;}
.fw-200 {font-weight: 200;}
.fw-300 {font-weight: 300;}
.fw-400 {font-weight: 400;}
.fw-500 {font-weight: 500;}
.fw-600 {font-weight: 600;}
.fw-700, .bold {font-weight: 700;}
.italic {font-style: italic;}
