.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 1px;}
.mb-2 {margin-bottom: 2px;}
.mb-3 {margin-bottom: 3px;}
.mb-4 {margin-bottom: 4px;}
.mb-5 {margin-bottom: 5px;}
.mb-6 {margin-bottom: 6px;}
.mb-7 {margin-bottom: 7px;}
.mb-8 {margin-bottom: 8px;}
.mb-10 {margin-bottom: 10px;}
.mb-12 {margin-bottom: 12px;}
.mb-13 {margin-bottom: 13px;}
.mb-14 {margin-bottom: 14px;}
.mb-15 {margin-bottom: 15px;}
.mb-16 {margin-bottom: 16px;}
.mb-19 {margin-bottom: 19px;}
.mb-20 {margin-bottom: 20px;}
.mb-22 {margin-bottom: 22px;}
.mb-24 {margin-bottom: 24px;}
.mb-30 {margin-bottom: 30px;}
.mb-40 {margin-bottom: 40px;}
.mb-48 {margin-bottom: 48px;}
.mb-80 {margin-bottom: 80px;}
.mb-100 {margin-bottom: 100px;}
.mr-2 {margin-right: 2px;}
.mr-3 {margin-right: 3px;}
.mr-4 {margin-right: 4px;}
.mr-5 {margin-right: 5px;}
.mr-8 {margin-right: 8px;}
.mr-10 {margin-right: 10px;}
.mr-12 {margin-right: 12px;}
.mr-14 {margin-right: 14px;}
.mr-16 {margin-right: 16px;}
.mr-18 {margin-right: 18px;}
.mr-20 {margin-right: 20px;}
.mr-22 {margin-right: 22px;}
.mr-24 {margin-right: 24px;}
.mr-30 {margin-right: 30px;}
.mr-38 {margin-right: 38px;}
.mr-40 {margin-right: 40px;}
.ml-2 {margin-left: 2px;}
.ml-3 {margin-left: 3px;}
.ml-4 {margin-left: 4px;}
.ml-5 {margin-left: 5px;}
.ml-6 {margin-left: 6px;}
.ml-8 {margin-left: 8px;}
.ml-9 {margin-left: 9px;}
.ml-10 {margin-left: 10px;}
.ml-11 {margin-left: 11px;}
.ml-12 {margin-left: 12px;}
.ml-16 {margin-left: 16px;}
.ml-18 {margin-left: 18px;}
.ml-20 {margin-left: 20px;}
.ml-22 {margin-left: 22px;}
.ml-24 {margin-left: 24px;}
.ml-30 {margin-left: 30px;}
.ml-32 {margin-left: 32px;}
.ml-33 {margin-left: 33px;}
.ml-40 {margin-left: 40px;}
.ml-48 {margin-left: 48px;}
.ml-50 {margin-left: 50px;}
.ml-54 {margin-left: 54px;}
.ml-60 {margin-left: 60px;}
.ml-90 {margin-left: 90px;}
.ml-100 {margin-left: 100px;}
.ml-128 {margin-left: 128px;}
.ml-135 {margin-left: 135px;}
.ml-145 {margin-left: 145px;}
.ml-177 {margin-left: 177px;}
.ml-235 {margin-left: 235px;}
.mt-0 {margin-top: 0;}
.mt-2 {margin-top: 2px;}
.mt-3 {margin-top: 3px;}
.mt-4 {margin-top: 4px;}
.mt-5 {margin-top: 5px;}
.mt-6 {margin-top: 6px;}
.mt-8 {margin-top: 8px;}
.mt-10 {margin-top: 10px;}
.mt-12 {margin-top: 12px;}
.mt-14 {margin-top: 14px;}
.mt-15 {margin-top: 15px;}
.mt-16 {margin-top: 16px;}
.mt-18 {margin-top: 18px;}
.mt-19 {margin-top: 19px;}
.mt-20 {margin-top: 20px;}
.mt-22 {margin-top: 22px;}
.mt-24 {margin-top: 24px;}
.mt-29 {margin-top: 29px;}
.mt-32 {margin-top: 32px;}
.mt-39 {margin-top: 39px;}
.mt-44 {margin-top: 44px;}
.mt-50 {margin-top: 50px;}
.mt-60 {margin-top: 60px;}
.mt-74 {margin-top: 74px;}
.mt-77 {margin-top: 77px;}
.mt-80 {margin-top: 80px;}
.mt-minus-14 {margin-top: -14px;}
.mt-minus-30 {margin-top: -30px;}
.m-0 {margin: 0;}
.m-2 {margin: 2px;}
.m-3 {margin: 3px;}
.m-10 {margin: 10px;}
.m-12 {margin: 12px;}
.m-16 {margin: 16px;}
.m-22 {margin: 22px;}
.m-24 {margin: 24px;}
.m-30 {margin: 30px;}
